import { inject, Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notification = inject(NzNotificationService);

  createNotification(type: string, title: string, content: string): void {
    this._notification.create(type, title, content);
  }
}
