import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {
  @Input() nzTotal: number = 0;
  @Input() nzPageSize: number = 8;
  @Output() indexChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Al cambiamento di pagina emitto il cambiamento al componente padre
   * @param i indice della pagina cliccata
   */
  onIndexChange(i: number){
    this.indexChange.emit(i);
  }
}
