import { Component, inject, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { SidebarButtonConfig } from '../../../core/interfaces/button-config';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent extends BaseComponent implements OnInit{
  private _auth = inject(AuthService);

  upperButtonsConfig: Array<SidebarButtonConfig> = [
    {
      label: 'Dashboard',
      icon: '/icons/sidebar/dashboard.svg',
      callback:  () => this.goTo('app/dashboard'),
      selectionKeyword: 'dashboard',
      selected: false,
      showOption: this.canShowOption(['applicant', 'lessor'])
    },
    {
      label: 'Le mie richieste',
      icon: '/icons/sidebar/email.svg',
      callback:  () => this.goTo('app/applications'),
      selectionKeyword: 'applications',
      selected: false,
      showOption: this.canShowOption(['applicant'])
    },
    {
      label: 'Carica alloggio',
      icon: '/icons/sidebar/email.svg',
      callback:  () => this.goTo('app/applications'),
      selectionKeyword: 'applications',
      selected: false,
      showOption: this.canShowOption(['lessor'])
    },
    {
      label: 'Stanze',
      icon: '/icons/sidebar/bed.svg',
      callback:  () => this.goTo('app/rooms'),
      selectionKeyword: 'rooms',
      selected: false,
      showOption: this.canShowOption(['applicant', 'lessor'])
    },
    {
      label: 'Appartamenti',
      icon: '/icons/sidebar/home.svg',
      callback:  () => this.goTo('app/apartments'),
      selectionKeyword: 'apartments',
      selected: false,
      showOption: this.canShowOption(['applicant', 'lessor'])
    },
    {
      label: 'Contratti',
      icon: '/icons/sidebar/contract.svg',
      callback:  () => this.goTo('app/contracts'),
      selectionKeyword: 'contracts',
      selected: false,
      showOption: this.canShowOption(['syndicate'])
    },
    {
      label: 'News',
      icon: '/icons/sidebar/news.svg',
      callback:  () => this.goTo('app/news'),
      selectionKeyword: 'news',
      selected: false,
      showOption: true
    }
  ]

  lowerButtonsConfig: Array<SidebarButtonConfig> = [
    {
      label: 'Account',
      icon: '/icons/sidebar/account.svg',
      callback:  () => null,
      selectionKeyword: 'account',
      selected: false,
      showOption: true
    },
    {
      label: 'Logout',
      icon: '/icons/sidebar/logout.svg',
      callback:  () => this._auth.logout(),
      selected: false,
      showOption: true
    }
  ]

  override ngOnInit(): void {
    super.ngOnInit();

    this.checkButtonSelection(this._router.url);

    //Ascolto gli eventi di navigazione e gestisco l'illuminazione delle varie opzioni
    this.current_route.subscribe({
      next: route => {
        this.checkButtonSelection(route);
      }
    })
  }

  /**
   * In base alla rotta modifica illuminazione e icona dell'opzione
   * @param route
   */
  private checkButtonSelection(route: string){
    this.upperButtonsConfig.forEach(button => {
      button.selected = this.isButtonSelected(route, button.selectionKeyword!);
      button.icon = button.selected ? button.icon!.replace('.svg', '-active.svg') : button.icon!.replace('-active.svg', '.svg');
    });

    this.lowerButtonsConfig.forEach(button => {
      button.selected = this.isButtonSelected(route, button.selectionKeyword!);
    });
  }

  /**
   * Ritorna true se l'url corrente contiene la keyword nel path
   * @param url url corrente
   * @param path keyword per far illuminare il bottone
   * @private
   */
  private isButtonSelected(url: string, path: string): boolean {
    const isSyndicate = this._auth.isSyndicate();
    const defaultPath = path == 'dashboard' && url == '/' && !isSyndicate;
    const syndicatePath = path == 'contracts' && url == '/' && isSyndicate;

    return url.includes(path) || defaultPath || syndicatePath;
  }

  /**
   * Ritorna true se l'utente loggato può visualizzare l'opzione
   * @param allowed_roles ruoli che possono visualizzare l'opzione
   * @private
   */
  private canShowOption(allowed_roles: Array<string>){
    const user_roles = this._auth.getUserRoles();
    return allowed_roles.some(role => user_roles.includes(role));
  }
}
