import {Component, Input} from '@angular/core';
import {SidebarButtonConfig} from '../../../core/interfaces/button-config';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrl: './sidebar-button.component.scss'
})
export class SidebarButtonComponent {
  @Input() config!: SidebarButtonConfig;
}
