import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss'
})
export class BaseComponent implements OnInit {
  public _router = inject(Router);

  current_route: ReplaySubject<string> = new ReplaySubject();

  ngOnInit() {
    this.onRouteChange();
  }

  /**
   * Ascolta i cambiamenti del routing e aggiorna il ReplaySubject
   */
  onRouteChange(){
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: route => {
        this.current_route.next(route.url);
      }
    })
  }

  /**
   * Va alla rotta definita
   * @param route url
   */
  goTo(route: string) {
    this._router.navigate([route]);
  }
}
