@switch (config.type){
  @case ('primary'){
    <button nz-button nzType="primary" [type]="config.submit ? 'submit' : 'button'" (click)="!config.submit ? config.callback() : null" class="primary">
      @if(config.icon){
        <img [src]="config.icon" alt="icon">
      }
      <span>{{config.label}}</span>
    </button>
  }
  @case ('link'){
    <a class="link" (click)="config.callback()">{{config.label}}</a>
  }
}
