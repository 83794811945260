<section class="items">
  <div class="upper-items">
    <img class="logo" src="/logos/habito.svg" alt="habito" (click)="goTo('')" onKeyDown="">

    <section class="options">
      @for(button of upperButtonsConfig; track button){
        @if(button.showOption){
          <app-sidebar-button [config]="button"></app-sidebar-button>
        }
      }
    </section>
  </div>

  <div class="lower-items">
    <section class="options">
      @for(button of lowerButtonsConfig; track button){
        <app-sidebar-button [config]="button"></app-sidebar-button>
      }
    </section>

    <section class="logos">
      <img src="/logos/eht.png" alt="eht">
      <img src="/logos/comune.png" alt="comune">
      <img src="/logos/regione.png" alt="regione">
    </section>
  </div>
</section>
