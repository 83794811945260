@if(config.formGroup){
  <form (submit)="config.submit_function(); submitted = true" [formGroup]="config.formGroup" [class]="config.form_class">
    @for (control of config.controls; track control){

      <div class="input-box" [class]="control.class">

        @if(control.label){
          <label [for]="control.key">{{control.label}}</label>
        }

        @switch (control.type){
          @case('input'){
            <input [ngClass]="{'invalid': isControlInvalid(control.key)}" nz-input [type]="control.input_type ?? 'text'" [formControlName]="control.key" [placeholder]="control.placeholder ?? ''"/>
          }
        }

        @if(isControlInvalid(control.key)){
          <span class="invalid-text">
            @if(getFormControl(control.key)?.hasError('required')){
              Il campo è richiesto
            } @else if(getFormControl(control.key)?.hasError('email')){
              Il campo deve essere un'email valida
            }
          </span>
        }
      </div>
    }

    <app-button [config]="config.submit_button"></app-button>
  </form>
}


