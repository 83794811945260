import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private requestCount = 0;
  loading$ = this.loadingSubject.asObservable();

  /**
   * Mostra il loader e aggiorna la variabile che conta le richieste in corso
   */
  showLoader() {
    this.requestCount++;
    this.loadingSubject.next(true);
  }

  /**
   * Nasconde il loader quando tutte le chiamate sono state eseguite
   */
  hideLoader() {
    this.requestCount--;
    if (this.requestCount <= 0) {
      this.loadingSubject.next(false);
    }
  }
}
