import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, finalize, Observable, tap } from 'rxjs';
import { LoaderService } from './loader.service';
import { inject, Injectable } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

export const NO_LOADER = new HttpContextToken<boolean>(() => false);
export const SUCCESS_MESSAGE = new HttpContextToken<boolean>(() => true);

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  private _loader = inject(LoaderService);
  private _router = inject(Router);
  private _notification = inject(NotificationService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrf_token = localStorage.getItem('csrf_token') ?? '';

    //Imposto la visibilità del loader se non è presente il parametro noLoader nel contesto della chiamata o se il metodo non è GET
    //Per non far visualizzare il messaggio in una singola chiamata basta inserire {context: new HttpContext().set(NO_LOADER, true)}
    if(!req.context.get(NO_LOADER) || req.method != 'GET'){
      this._loader.showLoader();
    }

    //Aggiungo il token di autenticazione all'header della richiesta
    const clonedReq = req.clone({
      setHeaders: {'x-csrf-token': csrf_token}, withCredentials: true
    });

    // Gestisco le risposte di successo ed errori separatamente
    return next.handle(clonedReq).pipe(
      //Chiamata HTTP con esito positivo
      tap((event: HttpEvent<any>) => {
        //Per non far visualizzare il messaggio in una singola chiamata basta inserire {context: new HttpContext().set(SUCCESS_MESSAGE, false)}
        if (event instanceof HttpResponse && req.context.get(SUCCESS_MESSAGE) && req.method != 'GET') {
          this._notification.createNotification('success', '', 'Operazione effettuata con successo');
        }
      }),

      //Chiamata HTTP con esito negativo
      catchError((error: HttpErrorResponse) => {
        let error_message = 'Qualcosa è andato storto';

        if (error.status === 401 || error.status === 403) {
          this._router.navigate(['auth/login']);
          localStorage.clear();
          error_message = 'Non sei autorizzato';
        }

        this._notification.createNotification('error', '', error_message);
        throw error;
      }),

      //Indipendentemente dall'esito rimuovo la visibilità del loader
      finalize(() => {
        this._loader.hideLoader();
      })
    );
  }
}
