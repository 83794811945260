import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

//NgZorro
import { NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzInputDirective} from 'ng-zorro-antd/input';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzPaginationComponent } from "ng-zorro-antd/pagination";

//Componenti
import { SidebarButtonComponent } from './components/sidebar-button/sidebar-button.component';
import { FormComponent } from './components/form/form.component';
import { BaseComponent } from './components/base/base.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ButtonComponent } from './components/button/button.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { PaginationComponent } from './components/pagination/pagination.component';

@NgModule({
  declarations: [
    BaseComponent,
    SidebarButtonComponent,
    SidebarComponent,
    FormComponent,
    ButtonComponent,
    RedirectComponent,
    PaginationComponent,
  ],
  exports: [
    SidebarButtonComponent,
    SidebarComponent,
    FormComponent,
    ButtonComponent,
    PaginationComponent
  ],
    imports: [
        CommonModule,
        NzButtonComponent,
        NzInputDirective,
        ReactiveFormsModule,
        NzFormLabelComponent,
        NzPaginationComponent
    ]
})
export class SharedModule { }
