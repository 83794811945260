import { Component, Input } from '@angular/core';
import { FormConfig } from '../../../core/interfaces/form-config';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent {
  @Input() config!: FormConfig;

  submitted: boolean = false;

  /**
   * Ritorna true se l'input è touched e il control è invalido, oppure se il form è submitted e il control è invalido
   * @param key chiave del FormControl
   */
  isControlInvalid(key: string): boolean {
    const touched_control = this.getFormControl(key)!.invalid && this.getFormControl(key)!.touched;
    const submitted_form = this.getFormControl(key)!.invalid && this.submitted;

    return touched_control || submitted_form;
  }

  /**
   * Ritorna il FormControl
   * @param key chiave del FormControl
   */
  getFormControl(key: string) {
    return (this.config.formGroup as FormGroup).get(key);
  }
}
